import { type KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useWordorContext } from './WordorContext'

export const WordInput = () => {
  const { currentInputAttempt: word, setCurrentInputAttempt: setWord } =
    useWordorContext()

  const inputs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]

  const [currentFocus, setCurrentFocus] = useState<number>(0)

  const onChar = (value: string, idx: number) => {
    let newFocus = 0
    console.log(value, idx)
    if (value != '') {
      if (idx == 0) {
        newFocus = 1
      } else if (idx == 1) {
        newFocus = 2
      } else if (idx == 2) {
        newFocus = 3
      } else if (idx == 3) {
        newFocus = 4
      } else if (idx == 4) {
        newFocus = 4
      }
    } else {
      newFocus = currentFocus ?? 0
    }

    setCurrentFocus(newFocus)

    const mWord = word
    mWord[idx] = value
    const newWord = [...mWord]
    setWord(newWord)
  }

  useEffect(() => {
    if (currentFocus !== undefined) {
      inputs[currentFocus]?.current?.focus()
    }
  }, [currentFocus])

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace') {
      // TODO: This does not play well with the onChange handler.
      if (currentFocus !== undefined) {
        if (inputs[currentFocus]?.current?.value == '') {
          event.preventDefault()
          setCurrentFocus(currentFocus - 1)
        }
      }
    }
  }

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const str = event.target.value
    const lastChar = str.charAt(str.length - 1)
    const val = lastChar.toUpperCase()
    if (val.toUpperCase() === val.toLowerCase() && val != '') {
      return // Non-alphabetical characters are not allowed
    }
    onChar(val, idx)
  }

  return (
    <div className="flex gap-2" onKeyDown={handleKeyPress}>
      <input
        ref={inputs[0]}
        type="text"
        className="h-[50px] w-[50px] border-4 border-black indent-3 text-[30px]"
        onChange={(event) => onChange(event, 0)}
        value={word[0]}
        onFocus={() => setCurrentFocus(0)}
      />
      <input
        ref={inputs[1]}
        type="text"
        className="h-[50px] w-[50px] border-4 border-black indent-3 text-[30px]"
        onChange={(event) => onChange(event, 1)}
        value={word[1]}
        onFocus={() => setCurrentFocus(1)}
      />
      <input
        ref={inputs[2]}
        type="text"
        className="h-[50px] w-[50px] border-4 border-black indent-3 text-[30px]"
        onChange={(event) => onChange(event, 2)}
        value={word[2]}
        onFocus={() => setCurrentFocus(2)}
      />{' '}
      <input
        ref={inputs[3]}
        type="text"
        className="h-[50px] w-[50px] border-4 border-black indent-3 text-[30px]"
        onChange={(event) => onChange(event, 3)}
        value={word[3]}
        onFocus={() => setCurrentFocus(3)}
      />
      <input
        ref={inputs[4]}
        type="text"
        className="h-[50px] w-[50px] border-4 border-black indent-3 text-[30px]"
        onChange={(event) => onChange(event, 4)}
        value={word[4]}
        onFocus={() => setCurrentFocus(4)}
      />
    </div>
  )
}
