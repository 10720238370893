import { useEffect, useRef } from 'react'
import { useAppContext } from '../../containers/AppContext'
import { ClickBlocker } from './ClickBlocker'
import { useSwipeable } from 'react-swipeable'
import { NavDrawerLink } from './NavDrawerLink'

export const NavDrawerMobile = () => {
  const { navDrawerOpened, setNavDrawerOpened } = useAppContext()
  const swipeHandler = useSwipeable({
    onSwipedLeft: () => navDrawerOpened && toggleDrawer(),
  })

  const toggleDrawer = () => {
    setNavDrawerOpened(!navDrawerOpened)
  }

  const drawerRef = useRef<HTMLDivElement>()
  const drawerRefPassthrough = (element: HTMLDivElement) => {
    swipeHandler.ref(element)
    drawerRef.current = element
  }

  const handleClickOutside = (event: any) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target) &&
      navDrawerOpened
    ) {
      toggleDrawer()
    }
  }

  useEffect(() => {
    if (navDrawerOpened) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [navDrawerOpened])

  return (
    <>
      {navDrawerOpened && <ClickBlocker />}
      <div
        className={`z-100000 fixed top-0 h-full w-[70vw] flex-col overflow-clip bg-gAzure transition-[left] duration-300
        ${navDrawerOpened ? 'left-0' : 'left-[-70vw]'}`}
        ref={drawerRefPassthrough}
      >
        <h1 className="flex justify-center py-6 font-mono text-lg">
          Grondijs Solutions
        </h1>
        <hr className="mb-2" />
        <div className="flex flex-col">
          <NavDrawerLink to="/">Home</NavDrawerLink>
          <NavDrawerLink to="/demos">Demos</NavDrawerLink>
          <NavDrawerLink to="/contact">Contact</NavDrawerLink>
        </div>
      </div>
    </>
  )
}
