import { useAppContext } from './AppContext'
import { GiHamburgerMenu } from 'react-icons/gi'

export const NavigationBarMobile = () => {
  const { landingOpened, setLandingOpened, setNavDrawerOpened } =
    useAppContext()

  const openDrawer = () => {
    setNavDrawerOpened(true)
  }

  return (
    <div className="absolute bottom-0 flex h-[50px] w-full bg-gAzure2 font-mono shadow-lg">
      <div className="ml-8 flex grow items-center font-mono text-lg">
        Grondijs Solutions
      </div>
      <div
        onClick={openDrawer}
        className="flex h-full w-[65px] cursor-pointer items-center justify-center bg-black text-3xl text-white"
      >
        <GiHamburgerMenu />
      </div>
    </div>
  )
}
