import TYPES from './types';

const registerAction = (baseType, triggerArgs) => ({
  start: (payload) => ({ type: `${baseType}_START`, payload }),
  success: (payload) => ({ type: `${baseType}_SUCCESS`, payload }),
  failed: (payload) => ({ type: `${baseType}_FAILED`, payload }),
  trigger: (payload) => ({ type: `${baseType}`, payload }),
});

const actions = {};

actions.fetchExampleData = registerAction(TYPES.FETCH_EXAMPLE_DATA);

// Corona
actions.fetchEUGeoJson = registerAction(TYPES.FETCH_EU_GEOJSON);

actions.fetchCoronaNewCases = registerAction(TYPES.FETCH_CORONA_NEW_CASES);
actions.fetchCoronaNewDeaths = registerAction(TYPES.FETCH_CORONA_NEW_DEATHS);
actions.fetchCoronaReproductionRate = registerAction(TYPES.FETCH_CORONA_REPRODUCTION_RATE);
actions.fetchCoronaHospitalization = registerAction(TYPES.FETCH_CORONA_HOSPITALIZATION);
actions.fetchCoronaRecentNumbers = registerAction(TYPES.FETCH_CORONA_RECENT_NUMBERS);
actions.fetchCoronaNewTests = registerAction(TYPES.FETCH_CORONA_NEW_TESTS);
actions.fetchCoronaCasesPerTest = registerAction(TYPES.FETCH_CORONA_CASES_PER_TEST);
actions.fetchCoronaTotalVaccinations = registerAction(TYPES.FETCH_CORONA_TOTAL_VACCINATIONS);
actions.fetchCoronaTotalBoosters = registerAction(TYPES.FETCH_CORONA_TOTAL_BOOSTERS);

actions.fetchCoronaData = registerAction(TYPES.FETCH_CORONA_DATA);
actions.fetchCoronaLastUpdated = registerAction(TYPES.FETCH_CORONA_LAST_UPDATED);

actions.selectCountry = (payload) => {return {type: TYPES.SELECT_COUNTRY, payload}};
actions.deselectCountry = (payload) => {return {type: TYPES.DESELECT_COUNTRY, payload}}
actions.resetSelectedCountries = (payload) => {return {type: TYPES.RESET_SELECTED_COUNTRIES}};
actions.updateSelectedCountries = (payload) => {return {type: TYPES.UPDATE_SELECTED_COUNTRIES, payload}};

actions.selectTimeRange = (payload) => {return {type: TYPES.SELECT_TIME_RANGE, payload}};
actions.togglePerMillion = (payload) => {return {type: TYPES.TOGGLE_PER_MILLION, payload}};

// Web analytics

actions.fetchRequestsTimeSeries = registerAction(TYPES.FETCH_REQUESTS_TIME_SERIES);
actions.fetchRequestsStats = registerAction(TYPES.FETCH_REQUESTS_STATS);
actions.fetchMostUsedPaths = registerAction(TYPES.FETCH_MOST_USED_PATHS);
actions.fetchCountriesTop10 = registerAction(TYPES.FETCH_COUNTRIES_TOP10);
actions.fetchMobileUsage = registerAction(TYPES.FETCH_MOBILE_USAGE);
actions.fetchResponseTimes = registerAction(TYPES.FETCH_RESPONSE_TIMES);

// App stuff
actions.updateExampleConfig = (payload) => {return {type: TYPES.UPDATE_EXAMPLE_CONFIG, payload}}

actions.toggleNavDrawer = () => {return {type: TYPES.TOGGLE_NAV_DRAWER}}
actions.openLanding = () => {return {type: TYPES.OPEN_LANDING}}
actions.closeLanding = () => {return {type: TYPES.CLOSE_LANDING}}

export default actions;