import { Outlet, useLocation } from 'react-router-dom'
import { useAppContext } from './AppContext'
import { NavigationBar } from './NavigationBar'
import { Footer } from './Footer'
import { useEffect } from 'react'
import { MParticle } from '../components/App/mParticle'
import { NavigationBarMobile } from './NavigationBarMobile'
import { NavDrawerMobile } from '../components/Drawer/NavDrawerMobile'

export default function App() {
  const location = useLocation()
  const { landingOpened, setLandingOpened, isMobile } = useAppContext()

  useEffect(() => {
    if (location.pathname !== '/') {
      setLandingOpened(true)
    }
  }, [])

  return (
    <div className="flex min-h-screen flex-col">
      <MParticle />
      <div
        className={`fixed left-0 top-0 z-20 w-screen transition-[height] duration-700 ${
          landingOpened ? (isMobile ? 'h-[50px]' : 'h-[60px]') : 'h-[50vh]'
        }`}
      >
        {isMobile ? <NavigationBarMobile /> : <NavigationBar />}
        {isMobile ? <NavDrawerMobile /> : null}
      </div>
      <div className={`${isMobile ? 'pt-20' : 'pt-24'}`}>
        <Outlet />
      </div>

      <div
        className={`z-9 fixed bottom-0 h-[30vh] w-full transition-[left] duration-700  ${
          landingOpened ? 'left-[-100vw]' : 'left-0'
        }`}
      >
        <Footer />
      </div>
    </div>
  )
}
