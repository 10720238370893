import { NavLink, useLocation } from 'react-router-dom'
import { useAppContext } from '../../containers/AppContext'

interface NavBarLinkProps {
  to: string
  text: string
}

export const NavBarLink = ({ to, text }: NavBarLinkProps) => {
  const location = useLocation()
  const { landingOpened, setLandingOpened } = useAppContext()

  const active = location.pathname.toString().includes(to)

  return (
    <NavLink
      onClick={() => setLandingOpened(true)}
      className={`flex h-full w-[100px] items-center justify-center text-black
      before:absolute
      before:top-0

      before:block
      before:h-[5px]
      ${active ? 'before:w-[100px]' : 'before:w-[0%]'}
      before:bg-black
      before:transition-[width]
      before:ease-in-out
      before:content-['']
      
      before:hover:w-[100px]`}
      to={to}
    >
      {text}
    </NavLink>
  )
}
