import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

interface DemoListNavLinkProps {
  to: string
}

export const DemoListNavLink = ({
  children,
  to,
}: PropsWithChildren<DemoListNavLinkProps>) => {
  return (
    <NavLink className="font-bold text-blue-700 underline" to={to}>
      {children}
    </NavLink>
  )
}
