import { PropsWithChildren, useEffect, useState } from 'react'
import { useAppContext } from '../../containers/AppContext'

export const ContentCard = ({ children }: PropsWithChildren) => {
  const { landingOpened, isMobile } = useAppContext()
  const [loaded, setLoaded] = useState<boolean>(false) // Secondary state needed for fade css fx

  useEffect(() => {
    if (landingOpened) setLoaded(true)
  }, [landingOpened])

  return (
    <div className={`z-10 flex justify-center`}>
      <div
        className={`relative mb-10 bg-white shadow-md transition-opacity duration-[1500ms] ${
          loaded ? 'opacity-100' : 'opacity-0'
        } ease-in ${isMobile ? 'w-[90vw] p-4' : 'w-[50vw] p-8'}`}
      >
        {children}
      </div>
    </div>
  )
}
