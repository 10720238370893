import createSagaMiddleware from 'redux-saga'
import { sagas } from './sagas'
import { applyMiddleware, createStore, compose } from 'redux'
import { rootReducer } from './reducers'
// import { createLogger } from 'redux-logger';

const sagaMiddleware = createSagaMiddleware()
// const logger = createLogger({
//   collapsed: true,
// })

const middlewares = [
  sagaMiddleware,
  //logger,
]

const enhancer = compose(applyMiddleware(...middlewares))

const store = createStore(rootReducer, {}, enhancer)
sagaMiddleware.run(sagas)

export { store }

export type CoronaState = ReturnType<typeof store.getState>
export type CoronaDispatch = typeof store.dispatch
