import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { MapContainer, GeoJSON } from 'react-leaflet'

import actions from './store/actions'
import LoadingSpinner from '../components/App/LoadingSpinner'
import { Feature } from 'geojson'
import { Layer, GeoJSON as GeoJSONLeaflet, LeafletMouseEvent } from 'leaflet'
import { useCoronaSelector, useCoronaDispatch } from './store/hooks'

const SelectedCountries = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000;
  display: flex;
`

const CountryLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;

  ${(props) =>
    props.color &&
    `
color: ${props.color};
`}
`

const StyledGeoJSON = styled(GeoJSON)`
  pointer-events: auto;
  cursor: pointer;
`

interface SelectedCountry {
  code: string
  name: string
  color: string
}

export default function CoronaMap() {
  const dispatch = useCoronaDispatch()
  const geoData = useCoronaSelector((state) => state.corona.euMap)
  const [selectedCountries, setSelectedCountries] = useState<SelectedCountry[]>(
    []
  )
  const geoJSONRef = useRef<GeoJSONLeaflet | null>(null)

  const selectedCountriesRef = useRef(selectedCountries)

  useEffect(() => {
    if (geoData.data === undefined) {
      dispatch(actions.fetchEUGeoJson.trigger())
    }
    dispatch(actions.resetSelectedCountries())
  }, [])

  if (geoData.data === undefined || geoData.isFetching || geoData.hasError) {
    if (geoData.isFetching === false) {
      if (geoData.hasError) {
        return <div>Something went wrong...</div>
      }
    } else {
      return <LoadingSpinner />
    }
  }

  const style = (feature: any) => {
    return {
      weight: 2,
      color: '#000',
    }
  }

  const hf = (e: LeafletMouseEvent) => {
    if (selectedCountriesRef.current.length > 0) {
      if (
        selectedCountriesRef.current
          .map((country) => country.code)
          .includes(e.target.feature.properties.iso3)
      ) {
        resetHighlight(e)
        selectedCountriesRef.current = selectedCountriesRef.current.filter(
          (country) => country.code !== e.target.feature.properties.iso3
        )
        setSelectedCountries(selectedCountriesRef.current)
        dispatch(actions.deselectCountry(e.target.feature.properties.iso3))
        return
      }
    }

    if (selectedCountriesRef.current.length > 1) {
      return
    }

    var color
    if (selectedCountriesRef.current.length === 0) color = 'blue'
    else {
      const usedColor = selectedCountriesRef.current[0].color
      color = usedColor === 'red' ? 'blue' : 'red'
    }

    e.target.setStyle({
      fillColor: color,
      stroke: '#000',
      fillOpacity: 1,
      opacity: 1,
    })
    selectedCountriesRef.current = selectedCountriesRef.current.concat({
      code: e.target.feature.properties.iso3,
      name: e.target.feature.properties.name,
      color,
    })
    setSelectedCountries(selectedCountriesRef.current)
    dispatch(
      actions.selectCountry({
        code: e.target.feature.properties.iso3,
        name: e.target.feature.properties.name,
        color,
      })
    )
  }

  const highlightFeature = (e: LeafletMouseEvent) => {
    console.log('onclick', selectedCountriesRef.current)
    if (selectedCountriesRef.current.length > 0) {
      if (
        selectedCountriesRef.current
          .map((country) => country.code)
          .includes(e.target.feature.properties.iso3)
      ) {
        resetHighlight(e)
        setSelectedCountries(
          selectedCountriesRef.current.filter(
            (country) => country.code !== e.target.feature.properties.iso3
          )
        )
        dispatch(actions.deselectCountry(e.target.feature.properties.iso3))
        return
      }
    }

    if (selectedCountriesRef.current.length > 1) {
      return
    }

    var color
    if (selectedCountriesRef.current.length === 0) color = 'blue'
    else {
      const usedColor = selectedCountriesRef.current[0].color
      color = usedColor === 'red' ? 'blue' : 'red'
    }

    e.target.setStyle({
      fillColor: color,
      stroke: '#000',
      fillOpacity: 1,
      opacity: 1,
    })
    console.log('1')
    setSelectedCountries(
      selectedCountriesRef.current.concat({
        code: e.target.feature.properties.iso3,
        name: e.target.feature.properties.name,
        color,
      })
    )
    dispatch(
      actions.selectCountry({
        code: e.target.feature.properties.iso3,
        name: e.target.feature.properties.name,
        color,
      })
    )
  }

  const resetHighlight = (e: LeafletMouseEvent) => {
    if (geoJSONRef.current !== null) {
      geoJSONRef.current.resetStyle(e.target)
    }
  }

  const onEachFeature = (feature: Feature, layer: Layer) => {
    layer.on({
      click: hf,
    })
  }

  return (
    <>
      <SelectedCountries>
        <div style={{ marginRight: '5px' }}>Selected countries:</div>
        {selectedCountries.map((c) => (
          <CountryLabel color={c.color} key={`selcountry_${c.name}`}>
            {c.name}
          </CountryLabel>
        ))}
      </SelectedCountries>
      <MapContainer
        // ref={mapRef}
        center={[55.0, 5.29]}
        zoom={4}
        style={{
          height: '100%',
          width: '100%',
          outline: 'none',
          overflow: 'hidden',
          zIndex: '10000',
        }}
        zoomControl={false}
        dragging={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
      >
        {geoData.data && (
          <StyledGeoJSON
            data={geoData.data}
            style={style}
            ref={geoJSONRef}
            onEachFeature={onEachFeature}
          />
        )}
      </MapContainer>
    </>
  )
}

export const a = 1
