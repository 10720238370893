import { Provider } from 'react-redux'
import { store } from '../corona/store/store'
import CoronaDashboard from '../corona/corona'

export const Corona = () => {
  return (
    <Provider store={store}>
      <CoronaDashboard />
    </Provider>
  )
}
