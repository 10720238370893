import { FaArrowLeft } from 'react-icons/fa'

export const NCryptAbout = () => {
  return (
    <>
      <FaArrowLeft className="absolute right-5 top-5" />
      <h3 className="text-xl font-bold">Why?</h3>
      <p>
        This application was developed in 2019 by me to learn about the complete
        cycle of product development. I wanted to make the journey from a vague
        idea to realizing a useful software solution.
      </p>
      <p>
        The idea of building a password manager originated while toying around
        with encryption algorithms with a friend. After learning the basics of
        mobile app development, I decided on using the Flutter framework. The
        big advantage of this framework is that I can develop for basically any
        device (Android, iOs, desktop, tablet, etc,) using a single codebase, as
        well having many great-looking prebuilt components to my disposal.
      </p>
      <p>
        The goal was to create a publicly available, free-to-use password
        management app on the Google Play Store.
      </p>
      <h3 className="text-xl font-bold">What?</h3>
      <p>
        The functional design of the application went through many iterations.
        Eventually I landed on the following design:
      </p>
      <p>
        <ul className="ml-6 list-disc">
          <li>
            Users can securely store their secrets (account details (passwords)
            & notes).
          </li>
          <li>User data is stored on disk encrypted.</li>
          <li>User data can only be read if you know the masterkey.</li>
          <li>Losing your master password or device means losing your data.</li>
          <li>Minimalist & easy-to-use layout, with some theming options.</li>
        </ul>
      </p>
      <h3 className="text-xl font-bold">How?</h3>
      <p>
        I spent approximately 6 months developing and designing this app. The
        application was developed and designed by me using{' '}
        <a
          className="text-blue-700 underline"
          href="https://flutter.io"
          target="_blank"
          rel="noreferrer"
        >
          Flutter
        </a>
        ,{' '}
        <a
          className="text-blue-700 underline"
          href="https://dart.dev"
          target="_blank"
          rel="noreferrer"
        >
          Dart
        </a>
        ,{' '}
        <a
          className="text-blue-700 underline"
          href="https://sqlite.org"
          target="_blank"
          rel="noreferrer"
        >
          SQLite
        </a>{' '}
        & the{' '}
        <a
          className="text-blue-700 underline"
          href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard"
          target="_blank"
          rel="noreferrer"
        >
          Advanced Encryption Standard
        </a>
        .
        <br />
        <br />I have more recently released{' '}
        <a
          className="text-blue-700 underline"
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=nl.hendrikgrondijs.ncrypt&hl=nl&gl=US"
        >
          v2.0.1
        </a>{' '}
        of the app and have made the source code for this app publicly available
        on{' '}
        <a
          className="text-blue-700 underline"
          target="_blank"
          rel="noreferrer"
          href="https://github.com/hlgrondijs/ncrypt"
        >
          Github
        </a>
        .
      </p>
      <h3 className="text-xl font-bold">Learnings</h3>
      <p>
        It is important to stay flexible in the design of a software
        application. With the design I do not mean just the "looks" of the app,
        but the entirety of all user stories, UI, technology choices etc. This
        type of flexibility is better known as agile development. Regular
        communication between the developers and stakeholders is crucial in
        making sure a solution is delivered that everybody is happy with. If I
        did not regularly revisit the product specifications as new insights
        developed, I would have not been able to create the app as I wanted it
        to be.
        <br />
        <br />
        Another important learning was the power of the Flutter framework. This
        frontend tooling is really valuable if one wants to create mobile native
        app for any device. I think it may be the future of frontend
        development, as it allows the programmer to develop for browser, native,
        desktop, mobile etc. all in one codebase with a very clear and
        maintainable syntax.
      </p>
    </>
  )
}
