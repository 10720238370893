import { CharacterHint } from './WordorContext'

interface CharacterDisplayProps {
  char: string
  state: CharacterHint
}

export const CharacterDisplay = ({ char, state }: CharacterDisplayProps) => {
  if (state !== undefined) {
    console.log(state as CharacterHint, CharacterHint.IN_WORD as CharacterHint)
  }

  return (
    <div
      className={`flex h-[50px] w-[50px] items-center justify-center border border-black text-[30px] ${
        state && state.toString() == 'IN_WORD' && 'bg-yellow-200'
      } ${state && state.toString() == 'CORRECT' && 'bg-green-500'}`}
    >
      {char}
    </div>
  )
}
