import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { TogglePerMillion } from './CoronaInfoTable'
import actions from './store/actions'
import MPlot from './MPlot'
import { useCoronaSelector } from './store/hooks'

export const ChartContainer = styled.div`
  // height: calc(100% - 50px);
  height: 400px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  height: min-content;
  padding-left: 25px;
  margin-bottom: 10px;
`

const TimeSelectContainer = styled.div`
  font-size: 14px;
`

const TimeSelect = styled.select`
  width: 150px;
  height: min-content;
  margin-left: 5px;
  border: 1px solid black;
`

const Description = styled.div`
  font-size: 14px;
  height: 10%;
`

const Toggles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default function TimeSeriesPlot({ styleMap }: any) {
  const selectedTimeRange = useCoronaSelector(
    (state) => state.corona.selectedTimeRange
  )
  const perMillion = useCoronaSelector((state) => state.corona.perMillion)
  const dispatch = useDispatch()
  const mdata: any = useCoronaSelector(
    (state) => (state.corona as any)[styleMap.meta.stat]
  )

  const selectPeriod = (e: any) => {
    dispatch(actions.selectTimeRange(e.target.value))
  }

  return (
    <>
      {mdata.data && Object.keys(mdata.data).length > 0 && (
        <Header>
          <FirstRow>
            <Description>
              {perMillion
                ? styleMap.meta.description_per_million
                : styleMap.meta.description}
            </Description>
          </FirstRow>
          <hr style={{ width: '100%' }} />
          <Toggles>
            <TimeSelectContainer>
              Time period
              <TimeSelect
                onChange={selectPeriod}
                defaultValue={selectedTimeRange}
              >
                <option value={'all_time'}>All time</option>
                <option value={'2_weeks'}>Past 2 weeks</option>
                <option value={'2_months'}>Past 2 months</option>
                <option value={'6_months'}>Past 6 months</option>
                <option value={'1_year'}>Past 1 year</option>
              </TimeSelect>
            </TimeSelectContainer>
            {!styleMap.meta.notPerMillion && <TogglePerMillion />}
          </Toggles>
        </Header>
      )}
      <ChartContainer>
        <MPlot styleMap={styleMap} />
      </ChartContainer>
    </>
  )
}
