import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import CoronaMap from './Map'
// import CoronaPlot from '../components/Plots/CoronaPlot'
import actions from './store/actions'
// import 'react-toggle/style.css'
import { IoMdReturnLeft } from 'react-icons/io'
import { NavLink } from 'react-router-dom'
import CoronaPlot from './CoronaPlot'
import { useAppContext } from '../containers/AppContext'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-evenly;

  padding: 0px 10px 0px 10px;
  min-height: calc(100vh - 90px);

  @media only screen and (max-width: 1000px) {
    padding: 75px 10px 0px 10px;
  }

  @media only screen and (max-width: 1400px) {
    display: none;
  }
`

const MapCard = styled.div`
  position: relative;
  height: 850px;
  width: 730px;
  padding: 10px;

  background-color: white;
  box-shadow: 0px 2px 10px 0px #dde;
`

const PlotCard = styled.div`
  // width: calc(80vw - 700px);
  margin-top: 45px;
  background-color: white;
  box-shadow: 0px 2px 10px 0px #dde;

  position: relative;
`

const PlotContainer = styled.div`
  height: 100%;
  padding: 20px;
`

const TopicSelector = styled.div`
  position: absolute;
  top: -40px;
  display: flex;
  flex-direction: row;
  // justify-content: space-evenly;

  width: 100%;
  // background-color: lightgray;
`

// const Topic = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 125px;
//   height: 40px;

//   text-align: center;
//   background-color: #eee;
//   box-shadow: 0px 2px 10px 0px #dde;
//   clip-path: inset(-5px -5px 0px -5px);
//   color: black;
//   border-left: 1px solid white;
//   border-right: 1px solid white;
//   border-radius: 10px 10px 0px 0px;
//   overflow: hidden;
//   cursor: pointer;
//   font-size: 12px;

//   // &:hover::before {
//   //   width: 150px;
//   // }

//   // &:before {
//   //   content: '';
//   //   display: block;
//   //   height: 5px;
//   //   background-color: #b6dbfc;
//   //   position: absolute;
//   //   top: 0px;
//   //   width: 0;

//   //   transition: all ease-in-out 250ms;
//   // }
// `

const LeftColumn = styled.div`
  height: 840px;
  width: calc(85vw - 700px);

  display: flex;
  flex-direction: column;
  justify-content: start;
`

const RecentNumbersCard = styled.div`
  background-color: white;
  box-shadow: 0px 2px 10px 0px #dde;
  // height: 110px;
  padding: 20px 25px 20px 25px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
`

const DashboardTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const Disclaimer = styled.span`
  font-size: 10px;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 5px;
`

const AboutButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;

  & > a {
    color: blue;
  }
`

const BackButton = styled.div`
  position: absolute;
  // top: 400px;
  // right: 200px;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`

const IntroParagraph = styled.div`
  margin-top: 10px;
  font-size: 14px;
`

const IntroParagraphTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0px 5px 0px;
`

const MobileContainer = styled.div`
  padding-top: 6rem;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 1400px) {
    display: none;
  }
`

const MobileContentCard = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0px 2px 10px 0px #dde;

  width: 800px;
  height: min-content;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;

  @media only screen and (max-width: 1000px) {
    width: calc(100vw - 40px - 5vw);
  }
`

export default function CoronaDashboard() {
  const dispatch = useDispatch()
  const [selectedTopic, setSelectedTopic] = useState('newCases')
  const selectedCountries = useSelector(
    (state: any) => state.corona.selectedCountries
  )
  const [currentView, setCurrentView] = useState('about')
  const lastUpdated = useSelector((state: any) => state.corona.lastUpdated)
  const { landingOpened } = useAppContext()
  const [loaded, setLoaded] = useState<boolean>(false) // Secondary state needed for fade css fx

  useEffect(() => {
    if (landingOpened) setLoaded(true)
  }, [landingOpened])

  const toggleCurrentView = () => {
    if (currentView === 'dashboard') setCurrentView('about')
    if (currentView === 'about') setCurrentView('dashboard')
  }

  useEffect(() => {
    if (selectedCountries.length > 0) {
      console.log('fetch corona data')
      dispatch(actions.fetchCoronaData.trigger())
    }
  }, [selectedCountries, dispatch])

  useEffect(() => {
    dispatch(actions.fetchCoronaLastUpdated.trigger())
  }, [])

  return (
    <div
      className={` transition-opacity duration-[1500ms] ${
        loaded ? 'opacity-100' : 'opacity-0'
      } ease-in`}
    >
      <MobileContainer>
        <MobileContentCard>
          {/* <BackButton>
            <NavLink
              to="/demos"
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <IoMdReturnLeft />
            </NavLink>
          </BackButton> */}
          Unfortunately, the COVID-19 dashboard is only supported on desktop or
          other devices with a large screen size (more than 1400 pixels wide).
        </MobileContentCard>
      </MobileContainer>
      <Container>
        <LeftColumn>
          <RecentNumbersCard>
            {/* <AboutButton onClick={toggleCurrentView}>
              <NavLink to={'/corona/about'}>About this app</NavLink>
            </AboutButton> */}
            <DashboardTitle>COVID-19 dashboard</DashboardTitle>
            <Disclaimer>Latest update: {lastUpdated.data}. </Disclaimer>
            <IntroParagraph>
              {/* This dashboard is automatically updated daily to explore COVID-19 related statistics in Europe. */}
              Raw data collection is provided by{' '}
              <a
                href="https://github.com/owid/covid-19-data/tree/master/public/data"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Our World In Data
              </a>
              , which in turn uses official government figures provided by the
              countries. Not all datasets are a 100% complete, so you may
              encounter gaps in the plots. The dashboard automatically updates
              every day with the newest figures.
              <IntroParagraphTitle>How to use</IntroParagraphTitle>
              Select up to two countries by clicking them on the map. Use this
              to compare statistics across the various topics. A country can de
              deselected by clicking on it again. Use the time period selection
              to zoom in on more recent periods and/or toggle displaying the
              data per million inhabitants with the toggle button.
            </IntroParagraph>
          </RecentNumbersCard>
          {/* <HeaderCard>
            {selectedCountry && <HeaderTitle>{selectedCountry ? selectedCountry.name : 'No selection'}</HeaderTitle>}
            <TogglePerMillion/>
            <CoronaInfoTable/>
          </HeaderCard> */}

          <PlotCard>
            <TopicSelector>
              <Topic
                active={selectedTopic === 'newCases'}
                onClick={() => setSelectedTopic('newCases')}
                label="New cases"
              />
              <Topic
                active={selectedTopic === 'newDeaths'}
                onClick={() => setSelectedTopic('newDeaths')}
                label="Deaths"
              />
              {/* <Topic active={selectedTopic===2} onClick={() => setSelectedTopic(2)}>R</Topic> */}
              <Topic
                active={selectedTopic === 'hospitalization'}
                onClick={() => setSelectedTopic('hospitalization')}
                label="Hospitalization"
              />
              <Topic
                active={selectedTopic === 'newTests'}
                onClick={() => setSelectedTopic('newTests')}
                label="Tests"
              />
              <Topic
                active={selectedTopic === 'casesPerTest'}
                onClick={() => setSelectedTopic('casesPerTest')}
                label="Cases per test"
              />
              <Topic
                active={selectedTopic === 'totalVaccinations'}
                onClick={() => setSelectedTopic('totalVaccinations')}
                label="Vaccinations"
              />
              <Topic
                active={selectedTopic === 'totalBoosters'}
                onClick={() => setSelectedTopic('totalBoosters')}
                label="Boosters"
              />
            </TopicSelector>
            <PlotContainer>
              {selectedTopic && <CoronaPlot stat={selectedTopic} />}
            </PlotContainer>
          </PlotCard>
        </LeftColumn>
        <MapCard>{<CoronaMap />}</MapCard>
      </Container>
    </div>
  )
}

interface TopicProps {
  onClick: () => void
  active: boolean
  label: string
}

const Topic = ({ onClick, active, label }: TopicProps) => {
  return (
    <div
      onClick={onClick}
      className={`
    topic flex h-[40px] w-[125px] cursor-pointer items-center justify-center overflow-hidden text-center text-[12px]
    ${active ? 'bg-white font-bold' : 'bg-[#eee] font-normal'}`}
    >
      {label}
    </div>
  )
}
