import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import { useAppContext } from '../../containers/AppContext'

interface NavDrawerButtonProps {
  to: string
}

export const NavDrawerLink = ({
  children,
  to,
}: PropsWithChildren<NavDrawerButtonProps>) => {
  const active =
    (window.location.pathname === '/' && to === '/') ||
    (window.location.pathname.includes(to) && to.length >= 2)

  const { setNavDrawerOpened, setLandingOpened } = useAppContext()

  const onClick = () => {
    if (to === '/') {
      setLandingOpened(false)
    } else {
      setLandingOpened(true)
    }
    setNavDrawerOpened(false)
  }

  return (
    <NavLink
      className={`m-1 flex justify-center  border-black p-1 py-2 font-mono text-lg shadow-xl
      ${active ? 'bg-black text-white' : 'bg-white text-black'}`}
      to={to}
      onClick={onClick}
    >
      {children}
    </NavLink>
  )
}
