import { ContentCard } from '../components/App/ContentCard'
import { DemoListItem } from '../components/App/DemoListItem'
import { DemoListNavLink } from '../components/App/DemoListNavLink'

export const Demos = () => {
  return (
    <ContentCard>
      <p>
        These applications are non-commisioned work meant for demonstration
        purposes and personal use.
      </p>

      {/* I learned that for development teams to deliver value to the organization transparency and communication between business and development is extremely important. I worked in various roles, such as full stack developer, analytic engineer, data scientist and as a scrum master.</p> */}
      <ul>
        <DemoListItem>
          <DemoListNavLink to="/demos/covid19">
            COVID-19 Dashboard
          </DemoListNavLink>
          <p>
            A browser-based COVID-19 data visualization tool that allows the
            user to compare statistics between two countries in the Europe
            region.
          </p>
        </DemoListItem>
        <DemoListItem>
          <DemoListNavLink to="/demos/ncrypt">
            nCrypt password manager
          </DemoListNavLink>
          <p>
            A native mobile application that uses cryptography to securely store
            secrets on Android smartphones.
          </p>
        </DemoListItem>
        <DemoListItem>
          <DemoListNavLink to="/demos/wordor">Wordor</DemoListNavLink>
          <p>A simple recreation of the famous Wordle game. Not finished.</p>
        </DemoListItem>
      </ul>
    </ContentCard>
  )
}
