export class Api {
  BASE_URL: string

  constructor() {
    if (process.env.REACT_APP_API_URL !== undefined) {
      this.BASE_URL = `https://${process.env.REACT_APP_API_URL}`
    } else {
      this.BASE_URL = 'http://localhost:8000'
    }
  }

  async makeGuess(guess: string) {
    const url = `${this.BASE_URL}/wordor/make_guess?guess=${guess}`

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
    }).then((res) => {
      if (!res.ok) {
        throw Error('Could not make guess!')
      }
      return res.json()
    })
    return response
  }

  async newSession() {
    const url = `${this.BASE_URL}/wordor/new_session`

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
    }).then((res) => {
      if (!res.ok) {
        throw Error('Could not create new session!')
      }
      return res.json()
    })
    return response['session_uuid']
  }

  async wordorStats() {
    const url = `${this.BASE_URL}/wordor/stats`

    const response = await fetch(url).then((res) => {
      if (!res.ok) {
        throw Error('could not fetch')
      }
      return res.json()
    })
    return response
  }

  async fetchExampleData() {
    const t = await fetch('total_i10q.json').then((response) => response.json())
    return t
  }

  async fetchEUGeoJson() {
    const url = this.BASE_URL + '/corona/eu_map'
    const response = await fetch(url).then((res) => {
      if (!res.ok) {
        throw Error('could not fetch')
      }
      return res.json()
    })
    return response
  }

  async fetchCoronaLastUpdated() {
    const url = this.BASE_URL + '/corona/last_updated'
    const response = await fetch(url).then((res) => {
      if (!res.ok) {
        throw Error('could not fetch')
      }
      return res.json()
    })
    return response
  }

  async fetchCoronaNewCases(countries: any, params: any): Promise<any> {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(`${this.BASE_URL}/corona/new_cases/${country}`)
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaNewDeaths(countries: any, params: any) {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(`${this.BASE_URL}/corona/new_deaths/${country}`)
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaReproductionRate(countries: any) {
    const data: any = {}
    for (const country of countries) {
      const url = this.BASE_URL + `/corona/reproduction_rate/${country}`
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaHospitalization(countries: any, params: any) {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(`${this.BASE_URL}/corona/hospitalization/${country}`)
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaNewTests(countries: any, params: any) {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(`${this.BASE_URL}/corona/new_tests/${country}`)
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaCasesPerTest(countries: any, params: any) {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(`${this.BASE_URL}/corona/cases_per_test/${country}`)
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaTotalVaccinations(countries: any, params: any) {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(
        `${this.BASE_URL}/corona/total_vaccinations/${country}`
      )
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaTotalBoosters(countries: any, params: any) {
    const data: any = {}
    for (const country of countries) {
      const url = new URL(`${this.BASE_URL}/corona/total_boosters/${country}`)
      Object.keys(params).forEach((key) => {
        url.searchParams.append(key, params[key])
      })
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }

  async fetchCoronaRecentNumbers(countries: any) {
    const data: any = {}
    for (const country of countries) {
      const url = this.BASE_URL + `/corona/most_recent_numbers/${country}`
      const response = await fetch(url).then((res) => {
        if (!res.ok) {
          throw Error('could not fetch')
        }
        return res.json()
      })
      data[country] = response
    }
    return data
  }
}
