import { ContentCard } from '../components/App/ContentCard'

export const Contact = () => {
  return (
    <ContentCard>
      <div className="flex w-full flex-col flex-wrap items-center justify-center gap-4 text-center font-mono text-[14px] font-light">
        <div>Hendrik Grondijs</div>
        <div>info@hendrikgrondijs.nl</div>
        <div>Leiden, NL</div>
        <a
          className="color-blue underline"
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/hendrik-grondijs-01544117/"
        >
          LinkedIn
        </a>
      </div>
    </ContentCard>
  )
}
