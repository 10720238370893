import { NavLink } from 'react-router-dom'
import { NavBarLink } from '../components/App/NavBarLink'
import { useAppContext } from './AppContext'

export const NavigationBar = () => {
  const { landingOpened, setLandingOpened } = useAppContext()

  return (
    <div className="absolute bottom-0 flex h-[60px] w-full gap-8 bg-gAzure2 font-mono shadow-lg">
      <NavLink
        onClick={() => landingOpened && setLandingOpened(false)}
        className="flex h-full items-center justify-center whitespace-nowrap bg-black px-6 text-xl text-white"
        to="/"
      >
        Grondijs Solutions
      </NavLink>
      <div className="ml-8 flex w-[90%] grow items-center gap-12">
        <NavBarLink to="/demos" text="Demos" />
        <NavBarLink to="/contact" text="Contact" />
      </div>
    </div>
  )
}
