import { KeyboardEvent, useEffect, useState } from 'react'
import { WordDisplay } from './WordDisplay'
import { WordInput } from './WordInput'
import { CharacterHint, useWordorContext } from './WordorContext'
import { FaArrowRotateLeft } from 'react-icons/fa6'

function checkWin(res: CharacterHint[]) {
  let numberCorrect = 0
  for (const c of res) {
    if (c.toString() === 'CORRECT') {
      numberCorrect++
    }
  }
  if (numberCorrect === 5) {
    return true
  }
  return false
}

export const WordorGame = () => {
  const {
    api,
    turn,
    setTurn,
    attempts,
    setAttempts,
    currentInputAttempt,
    setCurrentInputAttempt,
    sessionUuid,
    setSessionUuid,
    resetGame,
    gameState,
    setGameState,
  } = useWordorContext()

  useEffect(() => {
    api.newSession().then((uuid) => {
      setSessionUuid(uuid)
    })
  }, [])

  const submitAttempt = () => {
    // validate
    const guess = currentInputAttempt.join('')
    if (guess.length != 5) {
      return
    }

    // post the guess
    api.makeGuess(guess).then((res) => {
      if (checkWin(res)) {
        setGameState({
          isOver: true,
          isWin: true,
        })
      } else if (turn === 5) {
        setGameState({
          isOver: true,
          isWin: false,
        })
      }

      // handle response
      const newAttempts = [...attempts]
      newAttempts[turn] = {
        word: guess,
        solutionArray: res,
      }
      setAttempts(newAttempts)
      setTurn(turn + 1)
      setCurrentInputAttempt(['', '', '', '', ''])
    })
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      submitAttempt()
    }
  }

  const restart = () => {
    resetGame()
    api.newSession().then((uuid) => {
      setSessionUuid(uuid)
    })
  }

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-start" onKeyDown={handleKeyPress}>
        <div className="flex w-full text-2xl font-bold">Wordor</div>
        <div className="flex text-xs">
          Wordor is about finding the word! Try to spend as little guesses as
          possible finding the solution.
        </div>
        <div className="flex w-full flex-col items-center justify-between py-1">
          <div>{`Turn: ${turn + (gameState.isWin ? 0 : 1)}`}</div>
          <div className=" text-[10px]">{`GAME ID: ${sessionUuid}`}</div>
        </div>
        {gameState.isOver ? (
          gameState.isWin ? (
            <div className="mb-2 flex w-full justify-center gap-4 bg-green-600 py-2 text-2xl">
              <div>YOU WIN!</div>
              <button onClick={restart} className="flex items-center">
                <FaArrowRotateLeft />
              </button>
            </div>
          ) : (
            <div className="mb-2 flex w-full justify-center gap-4 bg-red-600 py-2 text-2xl">
              <div>GAME OVER</div>
              <button onClick={restart} className="flex items-center">
                <FaArrowRotateLeft />
              </button>
            </div>
          )
        ) : null}
        <div className="flex w-full flex-col items-center gap-2">
          {attempts.map((attempt, idx) => {
            if (idx === turn && !gameState.isOver) {
              return <WordInput />
            } else {
              return (
                <WordDisplay
                  key={`word_display_key_${idx}`}
                  attempt={attempt}
                />
              )
            }
          })}
        </div>
        {/* {!gameState.isOver && (
        <div className="flex w-full justify-center">
          <button
            onClick={submitAttempt}
            className="mt-4 w-20 border border-black bg-gSlate"
          >
            Submit
          </button>
        </div>
      )} */}
      </div>
    </div>
  )
}
