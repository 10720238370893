import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Api } from '../api'

interface GameState {
  isOver: boolean
  isWin: boolean
}

interface WordorStatistics {
  total_count: number
  won_count: number
}

type WordorContext = {
  turn: number
  setTurn: React.Dispatch<React.SetStateAction<number>>
  attempts: (Attempt | undefined)[]
  setAttempts: React.Dispatch<React.SetStateAction<(Attempt | undefined)[]>>
  api: Api
  currentInputAttempt: string[]
  setCurrentInputAttempt: React.Dispatch<React.SetStateAction<string[]>>
  sessionUuid: string | undefined
  setSessionUuid: React.Dispatch<React.SetStateAction<string | undefined>>
  resetGame: () => void
  gameState: GameState
  setGameState: React.Dispatch<React.SetStateAction<GameState>>
  refreshStats: () => void
  stats: WordorStatistics | undefined
  setStats: React.Dispatch<React.SetStateAction<WordorStatistics | undefined>>
}

export enum CharacterHint {
  FALSE,
  CORRECT,
  IN_WORD,
}
export interface Attempt {
  word: string
  solutionArray: CharacterHint[]
}

const WordorContext = createContext<WordorContext | null>(null)

export const useWordorContext = () => {
  const context = useContext(WordorContext)
  if (context == null) {
    throw Error('No WordorContext available')
  }
  return context
}

export function WordorContextProvider({ children }: PropsWithChildren) {
  const [turn, setTurn] = useState<number>(0)
  const [attempts, setAttempts] = useState<(Attempt | undefined)[]>([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ])
  const [currentInputAttempt, setCurrentInputAttempt] = useState<string[]>([
    '',
    '',
    '',
    '',
    '',
  ])
  const [sessionUuid, setSessionUuid] = useState<string>()
  const [gameState, setGameState] = useState({
    isOver: false,
    isWin: false,
  })
  const [stats, setStats] = useState<WordorStatistics>()

  const api = useMemo(() => new Api(), [])

  const resetGame = () => {
    setAttempts([
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ])
    setTurn(0)
    setCurrentInputAttempt(['', '', '', '', ''])
    setGameState({
      isOver: false,
      isWin: false,
    })
    refreshStats()
  }

  const refreshStats = () => {
    api.wordorStats().then((data) => {
      setStats(data)
    })
  }

  useEffect(() => {
    refreshStats()
  }, [])

  const providerValue = useMemo(() => {
    return {
      turn,
      setTurn,
      attempts,
      setAttempts,
      api,
      currentInputAttempt,
      setCurrentInputAttempt,
      sessionUuid,
      setSessionUuid,
      resetGame,
      gameState,
      setGameState,
      refreshStats,
      stats,
      setStats,
    }
  }, [
    turn,
    setTurn,
    currentInputAttempt,
    setCurrentInputAttempt,
    sessionUuid,
    setSessionUuid,
    attempts,
    setAttempts,
    gameState,
    setGameState,
    resetGame,
    refreshStats,
    stats,
    setStats,
  ])

  return (
    <WordorContext.Provider value={providerValue}>
      {children}
    </WordorContext.Provider>
  )
}
