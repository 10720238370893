import TimeSeriesPlot from './TimeSeriesPlot'

interface CoronaPlotProps {
  stat: string
}

export default function CoronaPlot({ stat }: CoronaPlotProps) {
  const newCasesStyleMap = {
    new_cases: {
      color_blue: '#b6dbfc',
      color_red: 'pink',
      label: 'New cases',
      lineType: 'monotone',
      strokeWidth: 1,
      showTooltip: false,
    },
    new_cases_smoothed: {
      color_blue: 'blue',
      color_red: 'red',
      label: '7 day average',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      description: 'New confirmed cases of COVID-19.',
      description_per_million:
        'New confirmed cases of COVID-19 per million inhabitants.',
      stat: 'newCases',
    },
  }

  const newDeathsStyleMap = {
    new_deaths: {
      color_blue: '#b6dbfc',
      color_red: 'pink',
      label: 'New deaths',
      lineType: 'monotone',
      strokeWidth: 1,
      showTooltip: false,
    },
    new_deaths_smoothed: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'New deaths (7 day average)',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      description:
        'The amount of deaths attributed to COVID-19 infection on a given date.',
      description_per_million:
        'The amount of deaths attributed to COVID-19 infection  on a given date per million inhabitants.',
      stat: 'newDeaths',
    },
  }

  const ReproductionRateStyleMap = {
    reproduction_rate: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'Reproduction rate',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      isFloat: true,
      description:
        'Estimate of the effective reproduction rate (R) of COVID-19.',
      description_per_million:
        'Estimate of the effective reproduction rate (R) of COVID-19.',
      stat: 'reproductionRate',
    },
  }

  const HospitalizationStyleMap = {
    hosp_patients: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'Hospitalized patients',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      description:
        "The number of COVID-19 patients in hospitals on a given day. This gives an indication of pressure on a country's healthcare sector. Note that this is not just the ICU hospitalization, but the total of patients hospitalized that are dianogised with COVID-19.",
      description_per_million:
        "The number of COVID-19 patients in hospital on a given day per million inhabitants. This gives an indication of pressure on a country's healthcare sector. Note that this is not just the ICU hospitalization, but the total of patients hospitalized that are dianogised with COVID-19.",
      stat: 'hospitalization',
    },
  }

  const newTestsStyleMap = {
    new_tests: {
      color_blue: '#b6dbfc',
      color_red: 'pink',
      label: 'New tests',
      lineType: 'monotone',
      strokeWidth: 1,
      showTooltip: false,
    },
    new_tests_smoothed: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'New tests (7 day average)',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      isFloat: true,
      description:
        'The amount of new COVID-19 tests administered on a given date.',
      description_per_million:
        'The amount of new COVID-19 tests administered on a given date per million inhabitants.',
      stat: 'newTests',
    },
  }

  const casesPerTestStyleMap = {
    new_cases: {
      color_blue: '#b6dbfc',
      color_red: 'pink',
      label: 'Cases per test',
      lineType: 'monotone',
      strokeWidth: 1,
      showTooltip: false,
    },
    new_cases_smoothed: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'Cases per test (7 day average)',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      isFloat: true,
      description:
        'The number of new cases on a date divided by the number of tests administered on that day. This results in an estimate of the ratio of positive COVID tests on a given day.',
      description_per_million:
        'The number of new cases on a date divided by the number of tests administered on that day. This results in an estimate of the ratio of positive COVID tests on a given day.',
      notPerMillion: true,
      stat: 'casesPerTest',
    },
  }

  const totalVaccinationsStyleMap = {
    total_vaccinations: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'Total vaccinations',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      isFloat: true,
      description:
        'The total number of vaccinations administered to the population of the selected country.',
      description_per_million:
        'The total number of vaccinations administered to the population of the selected country, per million inhabitants.',
      stat: 'totalVaccinations',
    },
  }

  const totalBoostersStyleMap = {
    total_boosters: {
      color_blue: 'blue',
      color_red: 'red',
      label: 'Total boosters',
      lineType: 'linear',
      strokeWidth: 3,
      useMax: true,
      showTooltip: true,
    },
    meta: {
      isFloat: true,
      description:
        'The total number of boosters administered to the population of the selected country.',
      description_per_million:
        'The total number of boosters administered to the population of the selected country, per million inhabitants.',
      stat: 'totalBoosters',
    },
  }

  var plot
  switch (stat) {
    case 'newCases':
      plot = <TimeSeriesPlot styleMap={newCasesStyleMap} />
      break
    case 'newDeaths':
      plot = <TimeSeriesPlot styleMap={newDeathsStyleMap} />
      break
    case 'reproductionRate':
      plot = <TimeSeriesPlot styleMap={ReproductionRateStyleMap} />
      break
    case 'hospitalization':
      plot = <TimeSeriesPlot styleMap={HospitalizationStyleMap} />
      break
    case 'newTests':
      plot = <TimeSeriesPlot styleMap={newTestsStyleMap} />
      break
    case 'casesPerTest':
      plot = <TimeSeriesPlot styleMap={casesPerTestStyleMap} />
      break
    case 'totalVaccinations':
      plot = <TimeSeriesPlot styleMap={totalVaccinationsStyleMap} />
      break
    case 'totalBoosters':
      plot = <TimeSeriesPlot styleMap={totalBoostersStyleMap} />
      break
    default:
      break
  }

  return <>{plot}</>
}
