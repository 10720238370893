import React, { PureComponent } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import LoadingSpinner from '../components/App/LoadingSpinner'
import { useCoronaSelector } from './store/hooks'

// class XAxisTick extends PureComponent {
//   render() {
//     const { x, y, payload, data, isMobile } = this.props
//     const t = moment(data[payload.value].timestamp)

//     return (
//       <g transform={`translate(${x},${y})`}>
//         <text
//           x={25}
//           y={0}
//           dy={16}
//           textAnchor="end"
//           fill="#666"
//           transform="rotate(0)"
//           fontSize={10}
//         >
//           {isMobile
//             ? t.format('MMM YYYY')
//             : data.length > 120
//             ? t.format('MMMM YYYY')
//             : t.format('DD MMMM YYYY')}
//         </text>
//       </g>
//     )
//   }
// }

interface XAxisTickProps {
  x: number
  y: number
  payload: any
  data: any
}

const XAxisTick = ({ x, y, payload, data }: any) => {
  const t = moment(data[payload.value].timestamp)
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={25}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(0)"
        fontSize={10}
      >
        {data.length > 120 ? t.format('MMMM YYYY') : t.format('DD MMMM YYYY')}
      </text>
    </g>
  )
}

const TooltipContainer = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px #dde;
  padding: 10px;
`

const TooltipValue = styled.span`
  // font-weight: bold;
`

const MTooltip = ({ active, payload, styleMap }: any) => {
  if (active && payload && payload.length) {
    const date = moment(payload[0].payload.timestamp)
    return (
      <TooltipContainer>
        <b>{date.format('MMM Do YYYY')}</b>
        {payload.map((pl: any, idx: number) => {
          const name = pl.name.slice(pl.name.indexOf('_') + 1)
          if (!styleMap[name].showTooltip) return null
          return (
            <div key={idx}>
              <span style={{ color: pl.stroke }}>{`${pl.name.slice(0, 3)} ${
                styleMap[name].label
              }: `}</span>
              <TooltipValue>{`${pl.value}`}</TooltipValue>
            </div>
          )
        })}
      </TooltipContainer>
    )
  }

  return null
}

export const ChartContainer = styled.div`
  // height: calc(100% - 50px);
  height: 400px;
`

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`

export default function MPlot({ styleMap }: any) {
  const selectedTimeRange = useCoronaSelector(
    (state) => state.corona.selectedTimeRange
  )
  const selectedCountries = useCoronaSelector(
    (state) => state.corona.selectedCountries
  )
  const mdata = useCoronaSelector(
    (state) => (state.corona as any)[styleMap.meta.stat]
  )

  if (
    mdata.data === undefined ||
    Object.keys(mdata.data).length === 0 ||
    mdata.isFetching ||
    mdata.hasError
  ) {
    if (mdata.isFetching === false || Object.keys(mdata).length === 0) {
      if (mdata.error) {
        return (
          <ChartContainer>
            <MessageContainer>
              Something when wrong fetching this data...
            </MessageContainer>
          </ChartContainer>
        )
      }
      return (
        <ChartContainer>
          <MessageContainer>
            Select a country on the map to begin.
          </MessageContainer>
        </ChartContainer>
      )
    } else {
      return (
        <ChartContainer>
          <LoadingSpinner />
        </ChartContainer>
      )
    }
  }

  const prepareData = (data: any) => {
    var pData = []

    var minTimestamp = moment('2050-01-01')
    var minTimestampCountry = ''
    var otherCountry = ''
    var otherTimestamp = undefined

    Object.keys(data).forEach((key) => {
      const firstTimeStamp = moment(data[key][0]['timestamp'])
      if (firstTimeStamp < minTimestamp) {
        minTimestampCountry = key
        minTimestamp = firstTimeStamp
      }
    })
    otherCountry = Object.keys(data).filter((k) => k !== minTimestampCountry)[0]
    if (otherCountry !== undefined)
      otherTimestamp = moment(data[otherCountry][0]['timestamp'])

    pData = data[minTimestampCountry]
    pData = pData.map((entry: any) => {
      const altObj = Object.fromEntries(
        Object.entries(entry).map(([k, v]) => {
          // Modify key here
          if (k !== 'timestamp') {
            return [`${minTimestampCountry}_${k}`, v]
          }
          return [k, v]
        })
      )
      return altObj
    })

    if (otherTimestamp !== undefined) {
      const offset = otherTimestamp.diff(minTimestamp, 'days')
      console.log(pData)
      pData = pData.map((entry: any, idx: number) => {
        if (idx >= offset && data[otherCountry][idx - offset] !== undefined) {
          console.log(data[otherCountry][idx - offset])
          const altObj = Object.fromEntries(
            Object.entries(data[otherCountry][idx - offset]).map(([k, v]) => {
              if (k !== 'timestamp') {
                return [`${otherCountry}_${k}`, v]
              }
              return [k, v]
            })
          )
          return {
            ...entry,
            ...altObj,
          }
        }
        return entry
      })
    }

    switch (selectedTimeRange) {
      case '2_weeks':
        pData = pData.slice(-14)
        break
      case '2_months':
        pData = pData.slice(-60)
        break
      case '6_months':
        pData = pData.slice(-180)
        break
      case '1_year':
        pData = pData.slice(-365)
        break
      default:
        break
    }

    pData.map((entry: any, idx: number) => {
      entry['index'] = idx
      return entry
    })
    return pData
  }

  const enrichedData = prepareData(mdata.data)
  const countries = Object.keys(mdata.data)

  // X & Y max
  const xMax = Math.floor(enrichedData.length)
  var yMaxLabel: string // Which variable to use for the yMax
  Object.keys(styleMap).forEach((key) => {
    if (styleMap[key].useMax) {
      yMaxLabel = key
    }
  })

  var yMaxArray: number[] = [] // All values to get the max from
  enrichedData.forEach((d: any) => {
    countries.forEach((country) => {
      yMaxArray.push(d[`${country}_${yMaxLabel}`])
    })
  })
  const yTempMax = Math.max.apply(
    Math,
    yMaxArray.filter((x) => isFinite(x))
  )
  const yMax = styleMap.meta?.isFloat
    ? yTempMax * 1.09
    : Math.ceil(yTempMax * 1.09)

  // X & Y Ticks
  const makeYTicks = () => {
    var ticks = []
    if (!styleMap.meta.isFloat || yMax > 1) {
      const roundedYMax = Math.ceil(yMax)
      const orderOfMagnitude = roundedYMax.toString().length - 2
      const firstDigit = roundedYMax.toString()[0]
      const secondDigit = roundedYMax.toString()[1]

      const maxTick = Math.floor(
        10 ** orderOfMagnitude *
          Math.floor(parseFloat(`${firstDigit}.${secondDigit}`) * 10)
      )

      ticks = [
        0,
        Math.floor(maxTick / 4),
        Math.floor(maxTick / 2),
        Math.floor(maxTick * 0.75),
        maxTick,
      ]
    } else {
      ticks = [
        0,
        (yMax / 4).toFixed(4),
        (yMax / 2).toFixed(4),
        (yMax * 0.75).toFixed(4),
        yMax.toFixed(4),
      ]
    }
    return ticks
  }
  const yTicks = makeYTicks()
  const xTicks = [
    0,
    Math.ceil(xMax / 4),
    Math.floor(xMax / 2),
    Math.floor(xMax * 0.75),
    xMax - 1,
  ]

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={enrichedData}
        margin={{ top: 25, left: 5, right: 25, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="index"
          type="number"
          domain={[0, xMax]}
          allowDataOverflow={true}
          interval={0}
          ticks={xTicks}
          tick={<XAxisTick data={enrichedData} />}
        />
        <YAxis
          type="number"
          domain={[0, yMax]}
          allowDataOverflow={true}
          ticks={yTicks}
          tick={{ fontSize: 11 }}
        />
        <Tooltip content={<MTooltip styleMap={styleMap} />} />
        {/* <Legend margin={{top: 50}}/> */}
        {/* <Legend content={renderLegend}/> */}
        {countries.map((country) => {
          const color = selectedCountries.filter((c) => c.code === country)[0]
            ?.color
          const a = Object.keys(styleMap).map((name, idx) => {
            const lineMap = styleMap[name]
            return (
              <Line
                connectNulls
                type={lineMap.lineType}
                dataKey={`${country}_${name}`}
                stroke={lineMap[`color_${color}`]}
                dot={false}
                strokeWidth={lineMap.strokeWidth}
                key={`${country}_${idx}`}
              />
            )
          })
          return a
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}
