export const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center pt-[50px]">
      <span>
        {/* <pre>Learn - Design - Plan - Develop - Test - Iterate</pre> */}
      </span>
      <p className="font-mono text-sm">Solving problems through technology</p>
    </div>
  )
}
