import {
  put,
  all,
  takeLatest,
  select,
  call,
  CallEffect,
} from 'redux-saga/effects'
import { Api } from '../../api'
import TYPES from './types'
import actions from './actions'

function* fetchExampledata(): any {
  const api = new Api()

  try {
    yield put(actions.fetchExampleData.start())
    const response = yield api.fetchExampleData()
    yield put(actions.fetchExampleData.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchExampleData.failed(message))
  }
}

function* fetchEUGeoJson(): any {
  const api = new Api()

  try {
    yield put(actions.fetchEUGeoJson.start())
    const response = yield api.fetchEUGeoJson()
    yield put(actions.fetchEUGeoJson.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchEUGeoJson.failed(message))
  }
}

function* fetchCoronaNewCases({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaNewCases.start())
    const response: any = yield api.fetchCoronaNewCases(
      payload.countries,
      params
    )
    yield put(actions.fetchCoronaNewCases.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaNewCases.failed(message))
  }
}

function* fetchCoronaNewDeaths({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaNewDeaths.start())
    const response = yield api.fetchCoronaNewDeaths(payload.countries, params)
    yield put(actions.fetchCoronaNewDeaths.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaNewDeaths.failed(message))
  }
}

function* fetchCoronaHospitalization({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaHospitalization.start())
    const response = yield api.fetchCoronaHospitalization(
      payload.countries,
      params
    )
    yield put(actions.fetchCoronaHospitalization.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaHospitalization.failed(message))
  }
}

function* fetchCoronaReproductionRate({ payload }: any): any {
  const api = new Api()

  try {
    yield put(actions.fetchCoronaReproductionRate.start())
    const response = yield api.fetchCoronaReproductionRate(payload.countries)
    yield put(actions.fetchCoronaReproductionRate.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaReproductionRate.failed(message))
  }
}

function* fetchCoronaNewTests({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaNewTests.start())
    const response = yield api.fetchCoronaNewTests(payload.countries, params)
    yield put(actions.fetchCoronaNewTests.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaNewTests.failed(message))
  }
}

function* fetchCoronaRecentNumbers({ payload }: any): any {
  const api = new Api()

  try {
    yield put(actions.fetchCoronaRecentNumbers.start())
    const response = yield api.fetchCoronaRecentNumbers(payload.countries)
    yield put(actions.fetchCoronaRecentNumbers.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaRecentNumbers.failed(message))
  }
}

function* fetchCoronaCasesPerTest({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaCasesPerTest.start())
    const response = yield api.fetchCoronaCasesPerTest(
      payload.countries,
      params
    )
    yield put(actions.fetchCoronaCasesPerTest.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaCasesPerTest.failed(message))
  }
}

function* fetchCoronaTotalVaccinations({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaTotalVaccinations.start())
    const response = yield api.fetchCoronaTotalVaccinations(
      payload.countries,
      params
    )
    yield put(actions.fetchCoronaTotalVaccinations.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaTotalVaccinations.failed(message))
  }
}

function* fetchCoronaTotalBoosters({ payload }: any): any {
  const api = new Api()
  const params = {
    per_million: payload.perMillion,
  }

  try {
    yield put(actions.fetchCoronaTotalBoosters.start())
    const response = yield api.fetchCoronaTotalBoosters(
      payload.countries,
      params
    )
    yield put(actions.fetchCoronaTotalBoosters.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaTotalBoosters.failed(message))
  }
}

function* togglePerMillion({ payload }: any): any {
  yield put(actions.fetchCoronaData.trigger({ perMillion: payload }))
}

function* fetchCoronaLastUpdated(): any {
  const api = new Api()

  try {
    yield put(actions.fetchCoronaLastUpdated.start())
    const response = yield api.fetchCoronaLastUpdated()
    yield put(actions.fetchCoronaLastUpdated.success(response))
  } catch ({ message }: any) {
    yield put(actions.fetchCoronaLastUpdated.failed(message))
  }
}

function* fetchCoronaData({ payload }: any): any {
  var countries = yield select((state) => state.corona.selectedCountries)
  countries = countries.map((country: any) => country.code)
  if (countries.length === 0) return

  // Check if country-data is already available
  const curNewCases = yield select((state) => state.corona.newCases)
  if (curNewCases.data !== undefined && payload === undefined) {
    const currentlyAvailableCountries = Object.keys(curNewCases?.data)
    countries = countries.filter((country: any) => {
      return !currentlyAvailableCountries.includes(country)
    })
  }

  var perMillion
  if (payload === undefined || payload.perMillion === undefined) {
    perMillion = yield select((state) => state.corona.perMillion)
  } else {
    perMillion = payload.perMillion
  }

  yield put(
    actions.fetchCoronaNewCases.trigger({
      countries,
      perMillion,
    })
  )

  yield put(
    actions.fetchCoronaNewDeaths.trigger({
      countries,
      perMillion,
    })
  )

  yield put(
    actions.fetchCoronaHospitalization.trigger({
      countries,
      perMillion,
    })
  )

  yield put(
    actions.fetchCoronaNewTests.trigger({
      countries,
      perMillion,
    })
  )

  yield put(
    actions.fetchCoronaCasesPerTest.trigger({
      countries,
      perMillion: false,
    })
  )

  yield put(
    actions.fetchCoronaTotalVaccinations.trigger({
      countries,
      perMillion,
    })
  )

  yield put(
    actions.fetchCoronaTotalBoosters.trigger({
      countries,
      perMillion,
    })
  )

  // yield put(actions.fetchCoronaReproductionRate.trigger({
  //   countries
  // }));

  yield put(
    actions.fetchCoronaRecentNumbers.trigger({
      countries,
    })
  )
}

// function* fetchRequestsTimeSeries({ payload }) {
//   const api = getApi()
//   try {
//     yield put(actions.fetchRequestsTimeSeries.start())
//     const response = yield api.fetchRequestsTimeSeries()
//     yield put(actions.fetchRequestsTimeSeries.success(response))
//   } catch ({ message }) {
//     yield put(actions.fetchRequestsTimeSeries.failed(message))
//   }
// }

// function* fetchRequestsStats({ payload }) {
//   const api = getApi()
//   try {
//     yield put(actions.fetchRequestsStats.start())
//     const response = yield api.fetchRequestsStats()
//     yield put(actions.fetchRequestsStats.success(response))
//   } catch ({ message }) {
//     yield put(actions.fetchRequestsStats.failed(message))
//   }
// }

// function* fetchMostUsedPaths({ payload }) {
//   const api = getApi()
//   try {
//     yield put(actions.fetchMostUsedPaths.start())
//     const response = yield api.fetchMostUsedPaths()
//     yield put(actions.fetchMostUsedPaths.success(response))
//   } catch ({ message }) {
//     yield put(actions.fetchMostUsedPaths.failed(message))
//   }
// }

// function* fetchCountriesTop10({ payload }) {
//   const api = getApi()
//   try {
//     yield put(actions.fetchCountriesTop10.start())
//     const response = yield api.fetchCountriesTop10()
//     yield put(actions.fetchCountriesTop10.success(response))
//   } catch ({ message }) {
//     yield put(actions.fetchCountriesTop10.failed(message))
//   }
// }

// function* fetchMobileUsage({ payload }) {
//   const api = getApi()
//   try {
//     yield put(actions.fetchMobileUsage.start())
//     const response = yield api.fetchMobileUsage()
//     yield put(actions.fetchMobileUsage.success(response))
//   } catch ({ message }) {
//     yield put(actions.fetchMobileUsage.failed(message))
//   }
// }

// function* fetchResponseTimes({ payload }) {
//   const api = getApi()
//   try {
//     yield put(actions.fetchResponseTimes.start())
//     const response = yield api.fetchResponseTimes()
//     yield put(actions.fetchResponseTimes.success(response))
//   } catch ({ message }) {
//     yield put(actions.fetchResponseTimes.failed(message))
//   }
// }

function* appListener() {
  yield takeLatest(TYPES.FETCH_EXAMPLE_DATA, fetchExampledata)
  yield takeLatest(TYPES.FETCH_CORONA_NEW_CASES, fetchCoronaNewCases)
  yield takeLatest(TYPES.FETCH_CORONA_NEW_DEATHS, fetchCoronaNewDeaths)
  yield takeLatest(
    TYPES.FETCH_CORONA_REPRODUCTION_RATE,
    fetchCoronaReproductionRate
  )
  yield takeLatest(
    TYPES.FETCH_CORONA_HOSPITALIZATION,
    fetchCoronaHospitalization
  )
  yield takeLatest(TYPES.FETCH_CORONA_RECENT_NUMBERS, fetchCoronaRecentNumbers)
  yield takeLatest(TYPES.FETCH_CORONA_NEW_TESTS, fetchCoronaNewTests)
  yield takeLatest(TYPES.FETCH_CORONA_CASES_PER_TEST, fetchCoronaCasesPerTest)
  yield takeLatest(
    TYPES.FETCH_CORONA_TOTAL_VACCINATIONS,
    fetchCoronaTotalVaccinations
  )
  yield takeLatest(TYPES.FETCH_CORONA_TOTAL_BOOSTERS, fetchCoronaTotalBoosters)

  yield takeLatest(TYPES.FETCH_EU_GEOJSON, fetchEUGeoJson)
  yield takeLatest(TYPES.FETCH_CORONA_LAST_UPDATED, fetchCoronaLastUpdated)
  yield takeLatest(TYPES.FETCH_CORONA_DATA, fetchCoronaData)
  yield takeLatest(TYPES.TOGGLE_PER_MILLION, togglePerMillion)

  // yield takeLatest(TYPES.FETCH_REQUESTS_TIME_SERIES, fetchRequestsTimeSeries)
  // yield takeLatest(TYPES.FETCH_REQUESTS_STATS, fetchRequestsStats)
  // yield takeLatest(TYPES.FETCH_MOST_USED_PATHS, fetchMostUsedPaths)
  // yield takeLatest(TYPES.FETCH_COUNTRIES_TOP10, fetchCountriesTop10)
  // yield takeLatest(TYPES.FETCH_MOBILE_USAGE, fetchMobileUsage)
  // yield takeLatest(TYPES.FETCH_RESPONSE_TIMES, fetchResponseTimes)
}

export function* sagas() {
  yield all([appListener()])
}
