const setTypes = (typesArray) =>
  typesArray.reduce(
    (memo, type) => ({
      ...memo,
      [type]: type,
      [`${type}_START`]: `${type}_START`,
      [`${type}_SUCCESS`]: `${type}_SUCCESS`,
      [`${type}_FAILED`]: `${type}_FAILED`,
    }),
    {}
  );

const types = [
  'FETCH_EXAMPLE_DATA',
  'UPDATE_EXAMPLE_CONFIG',
  'TOGGLE_NAV_DRAWER',
  'OPEN_LANDING',
  'CLOSE_LANDING',

  'FETCH_EU_GEOJSON',
  'FETCH_CORONA_NEW_CASES',
  'FETCH_CORONA_NEW_DEATHS',
  'FETCH_CORONA_REPRODUCTION_RATE',
  'FETCH_CORONA_HOSPITALIZATION',
  'FETCH_CORONA_RECENT_NUMBERS',
  'FETCH_CORONA_NEW_TESTS',
  'FETCH_CORONA_CASES_PER_TEST',
  'FETCH_CORONA_TOTAL_VACCINATIONS',
  'FETCH_CORONA_TOTAL_BOOSTERS',

  'FETCH_CORONA_DATA',
  'FETCH_CORONA_LAST_UPDATED',

  'SELECT_COUNTRY',
  'DESELECT_COUNTRY',
  'RESET_SELECTED_COUNTRIES',
  'UPDATE_SELECTED_COUNTRIES',

  'SELECT_TIME_RANGE',

  'TOGGLE_PER_MILLION',

  'FETCH_REQUESTS_TIME_SERIES',
  'FETCH_REQUESTS_STATS',
  'FETCH_MOST_USED_PATHS',
  'FETCH_COUNTRIES_TOP10',
  'FETCH_MOBILE_USAGE',
  'FETCH_RESPONSE_TIMES',
];

export default setTypes(types);