import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const MOBILE_THRESHOLD = 768

type AppContext = {
  landingOpened: boolean
  setLandingOpened: React.Dispatch<React.SetStateAction<boolean>>
  navDrawerOpened: boolean
  setNavDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
  isMobile: boolean
}

const AppContext = createContext<AppContext | null>(null)

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context == null) {
    throw Error('No AppContext available')
  }
  return context
}

export function AppContextProvider({ children }: PropsWithChildren) {
  const [landingOpened, setLandingOpened] = useState<boolean>(false)
  const [navDrawerOpened, setNavDrawerOpened] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)

  const windowResizeHandler = () => {
    if (window.innerWidth < MOBILE_THRESHOLD) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler)
    return () => window.removeEventListener('resize', windowResizeHandler)
  }, [])

  const providerValue = useMemo(() => {
    return {
      landingOpened,
      setLandingOpened,
      navDrawerOpened,
      setNavDrawerOpened,
      isMobile,
    }
  }, [
    landingOpened,
    isMobile,
    setLandingOpened,
    navDrawerOpened,
    setNavDrawerOpened,
  ])
  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  )
}
