import { useEffect, useState } from 'react'
import { useAppContext } from '../containers/AppContext'
import { WordorGame } from '../wordor/Game'
import { WordorContextProvider } from '../wordor/WordorContext'
import { ContentCard } from '../components/App/ContentCard'
import { WordorStats } from '../wordor/Stats'

export const Wordor = () => {
  return (
    <WordorContextProvider>
      <ContentCard>
        <WordorGame />
      </ContentCard>
      <ContentCard>
        <WordorStats />
      </ContentCard>
    </WordorContextProvider>
  )
}
