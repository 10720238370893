import { useState } from 'react'
import { ContentCard } from '../components/App/ContentCard'
import { NCryptAbout } from '../components/App/NCryptAbout'
import { NCryptApp } from '../components/App/NCryptApp'

export const Ncrypt = () => {
  const [showAbout, setShowAbout] = useState<boolean>(false)

  const toggleShowAbout = () => {
    setShowAbout(!showAbout)
  }

  return (
    <ContentCard>
      {showAbout ? (
        <NCryptAbout />
      ) : (
        <NCryptApp toggleShowAbout={toggleShowAbout} />
      )}
    </ContentCard>
  )
}
