import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import LoadingSpinner from '../components/App/LoadingSpinner'
import CountUp from 'react-countup'
import moment from 'moment'
import actions from './store/actions'
import Toggle from 'react-toggle'
import { useCoronaSelector } from './store/hooks'

const InfoTable = styled.table`
  width: 100%;

  @media only screen and (max-width: 1000px) {
    // margin: 0px 8px 0px 8px;
  }
`

const InfoTH = styled.th`
  background-color: #eee;
  font-weight: normal;
  font-size: 14px;

  padding: 5px;

  @media only screen and (max-width: 1000px) {
    font-size: 11px;
    padding: 5px;
  }
`

const InfoTD = styled.td`
  background-color: #eee;
  font-weight: bold;

  padding: 5px;
  text-align: center;
  font-size: 18px;

  @media only screen and (max-width: 1000px) {
    font-size: 11px;
    padding: 5px;
  }
`

const InfoTR = styled.tr``

const HeaderLabel = styled.span``

const HeaderDate = styled.span`
  font-size: 10px;
  @media only screen and (max-width: 1000px) {
    font-size: 8px;
  }
`

export default function CoronaInfoTable() {
  const perMillion = useCoronaSelector((state) => state.corona.perMillion)
  const selectedCountries = useCoronaSelector(
    (state) => state.corona.selectedCountries
  )
  const mdata = useCoronaSelector((state) => state.corona.recentNumbers)

  if (mdata.data === undefined || mdata.isFetching || mdata.hasError) {
    if (mdata.isFetching === false || Object.keys(mdata).length === 0) {
      if (mdata.hasError) {
        return <div>Something when wrong...</div>
      }
      return <div>Choose a country to begin.</div>
    } else {
      return <LoadingSpinner />
    }
  }

  // return <>123</>

  const d = (topic: any, data: any) => {
    var key = topic
    if (perMillion) {
      key = `${topic}_per_million`
    }
    return {
      date: data[key].date
        ? moment(data[key].date).format('MMM Do YYYY')
        : 'Not available',
      count: data[key].count,
    }
  }

  return (
    <>
      {selectedCountries.map((country) => {
        const countryData = mdata.data[country.code]

        const newCasesData = d('new_cases', countryData)
        const newDeathsData = d('new_deaths', countryData)
        const hospitalizationData = d('hosp_patients', countryData)
        return (
          <>
            <div>{country.code}</div>
            <InfoTable>
              <tbody>
                <InfoTR>
                  <InfoTH>
                    <div>
                      <HeaderLabel>New cases </HeaderLabel>
                      <HeaderDate>({newCasesData.date})</HeaderDate>
                    </div>
                  </InfoTH>
                  <InfoTD>
                    <CountUp end={newCasesData.count} duration={1} />
                  </InfoTD>
                  <InfoTH>
                    <HeaderLabel>New deaths </HeaderLabel>
                    <HeaderDate>({newDeathsData.date})</HeaderDate>
                  </InfoTH>
                  <InfoTD>
                    <CountUp end={newDeathsData.count} duration={1} />
                  </InfoTD>
                </InfoTR>
                <InfoTR>
                  <InfoTH>
                    <HeaderLabel>Reproduction rate </HeaderLabel>
                    <HeaderDate>
                      (
                      {countryData.reproduction_rate.date
                        ? moment(countryData.reproduction_rate.date).format(
                            'MMM Do YYYY'
                          )
                        : 'Not available'}
                      )
                    </HeaderDate>
                  </InfoTH>
                  <InfoTD>
                    <CountUp
                      end={countryData.reproduction_rate.count}
                      duration={1}
                      decimals={2}
                    />
                  </InfoTD>
                  <InfoTH>
                    <HeaderLabel>Hospitalized patients </HeaderLabel>
                    <HeaderDate>({hospitalizationData.date})</HeaderDate>
                  </InfoTH>
                  <InfoTD>
                    <CountUp end={hospitalizationData.count} duration={1} />
                  </InfoTD>
                </InfoTR>
              </tbody>
            </InfoTable>
          </>
        )
      })}
    </>
  )
}

const ToggleLabel = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;

  @media only screen and (max-width: 1000px) {
    font-size: 12px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export function TogglePerMillion() {
  const dispatch = useDispatch()
  const perMillion = useCoronaSelector((state) => state.corona.perMillion)

  const togglePerMillion = (e: any) => {
    dispatch(actions.togglePerMillion(!perMillion))
  }

  // useEffect(() => {
  //   dispatch(actions.fetchCoronaData.trigger())
  //   console.log(perMillion)
  // }, [perMillion])

  return (
    <div className="flex items-center">
      <label
        className="inline-block pr-[0.15rem] text-[14px] hover:cursor-pointer"
        htmlFor="flexSwitchCheckDefault"
      >
        Display data per million inhabitants
      </label>
      <div>
        <input
          // className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-gAzure checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-gAzure checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-gAzure checked:focus:bg-gAzure checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-gAzure dark:checked:after:bg-gAzure dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type="checkbox"
          role="switch"
          checked={perMillion}
          onChange={togglePerMillion}
          id="flexSwitchCheckDefault"
        />
      </div>
    </div>
  )

  return (
    <Container>
      <ToggleLabel>Display data per million inhabitants</ToggleLabel>
      <Toggle checked={perMillion} onChange={togglePerMillion} />
    </Container>
  )
}

export function ToggleCasesPerTest() {
  const dispatch = useDispatch()
  const casesPerTest = useCoronaSelector((state) => state.corona.casesPerTest)

  // const toggleCasesPerTest = () => {
  //   dispatch(actions.toggleCasesPerTest())
  // }

  // useEffect(() => {
  //   dispatch(actions.fetchCoronaData.trigger());
  //   console.log('p', casesPerTest);
  // }, [dispatch, casesPerTest]);

  return (
    <Container>
      <ToggleLabel>Display per test</ToggleLabel>
      {/* <Toggle checked={casesPerTest} onChange={toggleCasesPerTest} /> */}
    </Container>
  )
}
