import { useLocation } from 'react-router'
import App from '../../containers/App'
import { AppContextProvider } from '../../containers/AppContext'
import { useEffect } from 'react'

export const AppContainer = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <AppContextProvider>
      <App />
    </AppContextProvider>
  )
}
