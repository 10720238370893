import TYPES from './types'
import { combineReducers } from 'redux'

export const registerReducer =
  (
    baseType,
    {
      start = (state, payload) => ({
        ...state,
        isFetching: true,
      }),
      success = (state, payload) => ({
        ...state,
        data: payload,
        isFetching: false,
      }),
      failed = (state, payload) => ({
        ...state,
        error: payload,
        isFetching: false,
        hasError: true,
      }),
      initialState = {},
      more = (state, action) => {
        return state
      },
    } = {}
  ) =>
  (state = initialState, action) => {
    switch (action.type) {
      case `${baseType}_START`:
        return start(state, action.payload)
      case `${baseType}_SUCCESS`:
        return success(state, action.payload)
      case `${baseType}_FAILED`:
        return failed(state, action.payload)
      default:
        return more(state, action)
    }
  }

const exampleData = registerReducer(TYPES.FETCH_EXAMPLE_DATA, {})

function exampleConfig(
  state = {
    NL: {
      enabled: true,
      color: '#82ca9d',
    },
    BG: {
      enabled: true,
      color: '#8884d8',
    },
    TR: {
      enabled: true,
      color: '#FF0000',
    },
    HU: {
      enabled: true,
      color: '#00FF00',
    },
  },
  action
) {
  switch (action.type) {
    case TYPES.UPDATE_EXAMPLE_CONFIG:
      return action.payload
    default:
      return state
  }
}

const loc4 = combineReducers({ exampleData, exampleConfig })

function app(
  state = {
    navDrawerOpened: false,
    landingOpened: window.location.pathname === '/',
  },
  action
) {
  switch (action.type) {
    case TYPES.TOGGLE_NAV_DRAWER:
      return {
        ...state,
        navDrawerOpened: !state.navDrawerOpened,
      }
    case TYPES.OPEN_LANDING:
      return {
        ...state,
        landingOpened: true,
      }
    case TYPES.CLOSE_LANDING:
      return {
        ...state,
        landingOpened: false,
      }
    default:
      return state
  }
}

const euMap = registerReducer(TYPES.FETCH_EU_GEOJSON, {})

function newCases(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_NEW_CASES_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_NEW_CASES_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_NEW_CASES_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function newDeaths(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_NEW_DEATHS_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_NEW_DEATHS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_NEW_DEATHS_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function reproductionRate(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_REPRODUCTION_RATE_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_REPRODUCTION_RATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_REPRODUCTION_RATE_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function hospitalization(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_HOSPITALIZATION_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_HOSPITALIZATION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_HOSPITALIZATION_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function newTests(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_NEW_TESTS_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_NEW_TESTS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_NEW_TESTS_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function casesPerTest(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_CASES_PER_TEST_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_CASES_PER_TEST_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_CASES_PER_TEST_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function totalVaccinations(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_TOTAL_VACCINATIONS_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_TOTAL_VACCINATIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_TOTAL_VACCINATIONS_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

function totalBoosters(state = {}, action) {
  switch (action.type) {
    case TYPES.FETCH_CORONA_TOTAL_BOOSTERS_START:
      return { ...state, isFetching: true }
    case TYPES.FETCH_CORONA_TOTAL_BOOSTERS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isFetching: false,
      }
    case TYPES.FETCH_CORONA_TOTAL_BOOSTERS_FAILED:
      return { ...state, error: action.payload, isFetching: false }
    case TYPES.DESELECT_COUNTRY:
      const curData = state.data
      if (curData !== undefined) delete curData[action.payload]
      return { ...state, data: curData }
    case TYPES.RESET_SELECTED_COUNTRIES:
      return {}
    default:
      return state
  }
}

const recentNumbers = registerReducer(TYPES.FETCH_CORONA_RECENT_NUMBERS, {
  initialState: {},
  more: (state, action) => {
    switch (action.type) {
      case TYPES.SELECT_COUNTRY:
        return {}
      default:
        return state
    }
  },
})

const lastUpdated = registerReducer(TYPES.FETCH_CORONA_LAST_UPDATED, {})

function selectedCountries(state = [], action) {
  switch (action.type) {
    case TYPES.SELECT_COUNTRY:
      return [...state, action.payload]
    case TYPES.DESELECT_COUNTRY:
      console.log('quoi?')
      return [...state].filter((country) => country.code !== action.payload)
    case TYPES.RESET_SELECTED_COUNTRIES:
      return []
    default:
      return state
  }
}

function perMillion(state = true, action) {
  switch (action.type) {
    case TYPES.TOGGLE_PER_MILLION:
      return !state
    default:
      return state
  }
}

function selectedTimeRange(state = 'all_time', action) {
  switch (action.type) {
    case TYPES.SELECT_TIME_RANGE:
      return action.payload
    default:
      return state
  }
}

const requestsTimeSeries = registerReducer(TYPES.FETCH_REQUESTS_TIME_SERIES, {})
const requestsStats = registerReducer(TYPES.FETCH_REQUESTS_STATS, {})
const mostUsedPaths = registerReducer(TYPES.FETCH_MOST_USED_PATHS, {})
const countriesTop10 = registerReducer(TYPES.FETCH_COUNTRIES_TOP10, {})
const mobileUsage = registerReducer(TYPES.FETCH_MOBILE_USAGE, {})
const responseTimes = registerReducer(TYPES.FETCH_RESPONSE_TIMES, {})

const corona = combineReducers({
  newCases,
  newDeaths,
  reproductionRate,
  hospitalization,
  recentNumbers,
  newTests,
  casesPerTest,
  totalVaccinations,
  totalBoosters,

  euMap,

  lastUpdated,

  selectedCountries,
  selectedTimeRange,
  perMillion,
})

const apianalytics = combineReducers({
  requestsTimeSeries,
  requestsStats,
  mostUsedPaths,
  countriesTop10,
  mobileUsage,
  responseTimes,
})

export const rootReducer = combineReducers({
  loc4,
  app,
  corona,
  apianalytics,
})
