import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  MdOutlineClose,
  MdOutlineArrowBackIosNew,
  MdArrowForwardIos,
} from 'react-icons/md'

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  gap: 10px;

  // background-color: white;
  // box-shadow: 0px 2px 10px 0px #dde;

  padding: 25px;
  width: 720px;
  margin-bottom: 25px;

  @media only screen and (max-width: 1000px) {
    width: 85vw;
    padding: 15px;
    row-gap: 25px;
  }
`

const PreviewImage = styled.img`
  height: 300px;
  cursor: pointer;

  @media only screen and (max-width: 1000px) {
    height: 200px;
  }
`

const ImageViewer = styled.div`
  width: 90vw;
  height: 80vh;
  z-index: 999999;
  position: fixed;
  top: 10vh;
  left: 5vw;
  padding: 10px;

  background-color: aliceblue;
  color: black;
  font-size: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ImageViewerMobile = styled.div`
  width: min-content;
  height: 80vh;
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  background-color: red;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
`

const FullImage = styled.img`
  height: 100%;
  // width: 100%;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`

const BackButton = styled.div`
  padding-left: 20px;
  cursor: pointer;
`

const ForwardButton = styled.div`
  padding-right: 20px;
  cursor: pointer;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  opacity: 0.5;
  z-index: 1001;
  background-color: gray;
`

export default function Carousel() {
  const [imageViewerToggled, setImageViewerToggled] = useState(false)
  const [selectedImage, setSelectedImage] = useState<number>(0)

  const ref = useRef<HTMLDivElement | null>(null)

  const imageList = ['ncrypt_3.png', 'ncrypt_1.png', 'ncrypt_2.png']

  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      closeImage()
    }
  }

  const openImage = (index: any) => {
    setImageViewerToggled(true)
    setSelectedImage(index)
    document.addEventListener('mousedown', handleClickOutside)
  }

  const closeImage = () => {
    setImageViewerToggled(false)
    document.removeEventListener('mousedown', handleClickOutside)
  }

  const nextImage = () => {
    if (selectedImage === imageList.length - 1) {
      setSelectedImage(0)
    } else {
      setSelectedImage(selectedImage + 1)
    }
  }

  const previousImage = () => {
    if (selectedImage === 0) {
      setSelectedImage(imageList.length - 1)
    } else {
      setSelectedImage(selectedImage - 1)
    }
  }

  const isMobile = window.innerWidth < 750

  return (
    <Container>
      {imageList.map((image, idx) => {
        return (
          <PreviewImage
            src={`${window.location.origin}/${image}`}
            onClick={() => openImage(idx)}
            key={idx}
          />
        )
      })}
      {imageViewerToggled && !isMobile && (
        <>
          <ImageViewer ref={ref}>
            <BackButton onClick={previousImage}>
              <MdOutlineArrowBackIosNew />
            </BackButton>
            <FullImage
              src={`${window.location.origin}/${imageList[selectedImage]}`}
            />
            <ForwardButton onClick={nextImage}>
              <MdArrowForwardIos />
            </ForwardButton>
            <CloseButton onClick={closeImage}>
              <MdOutlineClose />
            </CloseButton>
          </ImageViewer>
          <Overlay />
        </>
      )}
      {imageViewerToggled && isMobile && (
        <>
          <ImageViewerMobile ref={ref}>
            <FullImage
              src={`${window.location.origin}/${imageList[selectedImage]}`}
            />
          </ImageViewerMobile>
          <Overlay />
        </>
      )}
    </Container>
  )
}
