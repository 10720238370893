import { useEffect, useState } from 'react'
import { useWordorContext } from './WordorContext'

export const WordorStats = () => {
  const { stats } = useWordorContext()

  return (
    <div>
      <div>Total count: {`${stats?.total_count}`}</div>
      <div>Won count: {`${stats?.won_count}`}</div>
    </div>
  )
}
