import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './containers/App'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { AppContextProvider } from './containers/AppContext'
import { Home } from './routes/Home'
import { Demos } from './routes/Demos'
// import { AboutMe } from './routes/AboutMe'
import { Contact } from './routes/Contact'
import { AppContainer } from './components/App/AppContainer'
import { Wordor } from './routes/Wordor'
import { Corona } from './routes/Corona'
import { Ncrypt } from './routes/Ncrypt'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppContainer />}>
      <Route index element={<div />} />
      <Route path="test" element={<div>test</div>} />
      <Route path="/demos" element={<Demos />} />
      <Route path="/demos/wordor" element={<Wordor />} />
      <Route path="/demos/covid19" element={<Corona />} />
      <Route path="/demos/ncrypt" element={<Ncrypt />} />
      <Route path="contact" element={<Contact />} />
    </Route>
  )
)

root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
