import { useEffect, useState } from 'react'
import { CharacterDisplay } from './CharacterDisplay'
import { Attempt, CharacterHint } from './WordorContext'

interface WordDisplayProps {
  attempt: Attempt | undefined
}

export const WordDisplay = ({ attempt }: WordDisplayProps) => {
  const [wordArray, setWordArray] = useState<string[]>([])
  const [solution, setSolution] = useState<CharacterHint[]>([])

  console.log(attempt)

  useEffect(() => {
    let arr = []
    if (attempt === undefined) {
      arr = ['', '', '', '', '']
      setSolution([
        CharacterHint.FALSE,
        CharacterHint.FALSE,
        CharacterHint.FALSE,
        CharacterHint.FALSE,
        CharacterHint.FALSE,
      ])
    } else {
      for (const c of attempt.word) {
        arr.push(c.toUpperCase())
      }
      console.log('>', attempt)
      setSolution(attempt.solutionArray)
    }

    setWordArray(arr)
  }, [attempt])

  return (
    <div className="flex gap-2">
      {wordArray.map((char, idx) => (
        <CharacterDisplay char={char} key={idx} state={solution[idx]} />
      ))}
    </div>
  )
}
