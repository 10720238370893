import React from 'react'
import styled from 'styled-components'
import PuffLoader from 'react-spinners/PuffLoader'

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export default function LoadingSpinner() {
  return (
    <Container>
      <PuffLoader />
    </Container>
  )
}
