import Carousel from './Carousel'

interface NCryptAppProps {
  toggleShowAbout: () => void
}

export const NCryptApp = ({ toggleShowAbout }: NCryptAppProps) => {
  return (
    <>
      <h1 className="text-2xl font-bold">nCrypt</h1>
      <h3 className="text-md  text-gray-400">A free to use password manager</h3>
      <p>
        nCrypt is a cryptographically secure password management app, developed
        for Android smartphones. Users can store their secrets{' '}
        <a
          className="text-blue-700 underline"
          target="_blank"
          href="https://nl.wikipedia.org/wiki/Advanced_Encryption_Standard"
        >
          AES
        </a>
        -encrypted on the device itself and can only ever be decrypted using
        your master password. An intuitive vault-like interface using material
        design principles makes the app easy to use and accessible.
      </p>
      <p>
        I personally use this app for storing my most important passwords in a
        secure digital way. Prioritizing simplicity and security, this simple
        app stores all your important passwords and secrets on your phone.
      </p>
      <p>
        You can try out the app on your Android device via the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue-700 underline"
          href="https://play.google.com/store/apps/details?id=nl.hendrikgrondijs.ncrypt"
        >
          Play Store
        </a>
        . I have also recently released the source code on{' '}
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue-700 underline"
          href="https://github.com/hlgrondijs/ncrypt"
        >
          Github
        </a>
        .
      </p>
      <p>
        <u
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={toggleShowAbout}
        >
          Click here
        </u>{' '}
        to read some thoughts about the making of this app.
      </p>
      <Carousel />
    </>
  )
}
